import { useEffect, useState } from "react";

const useIsWalletView = () => {
  const [walletView, setWalletView] = useState<boolean>(
    window.location.search.includes("walletView=true") ||
      navigator.userAgent.includes("CoopWallet")
  );

  useEffect(() => {
    if (
      window.location.search.includes("walletView=true") ||
      navigator.userAgent.includes("CoopWallet")
    )
      setWalletView(true);
  }, [window.location.search]);

  return walletView;
};

export default useIsWalletView;
