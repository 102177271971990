import { createContext } from "react";
import useIsWalletView from "../../hooks/useIsWalletView";

export interface IWalletViewContext {
  isWalletView: boolean;
}

export const WalletViewContext = createContext<IWalletViewContext>(
  {} as IWalletViewContext
);

export const WalletViewProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <WalletViewContext.Provider value={{ isWalletView: useIsWalletView() }}>
      {children}
    </WalletViewContext.Provider>
  );
};
