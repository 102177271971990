import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { WalletViewProvider } from "./context/walletView/walletViewProvider";

export enum FaqView {
  PAGE = "page",
  FOLD = "fold",
}

export enum FaqLanguage {
  EN = "en",
  RO = "ro",
}

interface IFaqProps {
  language: FaqLanguage;
  view: FaqView;
  show?: number;
}

declare global {
  interface Window {
    faqProps: IFaqProps;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("pluginFaq") as HTMLElement
);
root.render(
  <WalletViewProvider>
    <App />
  </WalletViewProvider>
);
