import { useContext } from "react";
import { WalletViewContext } from "../context/walletView/walletViewProvider";
import FaqFold from "./faq-fold";
import faqs from "../assets/faqs.json";
import "./faq-page.css";
import "./wallet-view.css";
import { FaqLanguage } from "..";

const FaqPage = ({ language }: { language: FaqLanguage }) => {
  const { isWalletView } = useContext(WalletViewContext);

  return (
    <div className="faq-page">
      {isWalletView && (
        <div className="wallet-view-nav">
          <span className="wallet-view-nav-text">
            {language === FaqLanguage.EN
              ? "Frequently Asked Questions"
              : "Întrebări frecvente"}
          </span>
        </div>
      )}
      <FaqFold
        data={faqs}
        show={faqs[language as keyof typeof faqs].length}
        language={language}
      ></FaqFold>
    </div>
  );
};

export default FaqPage;
