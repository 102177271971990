import FaqPage from "./components/faq-page";
import FaqFold from "./components/faq-fold";
import faqs from "./assets/faqs.json";
import { FaqLanguage, FaqView } from ".";
import { useContext } from "react";
import { WalletViewContext } from "./context/walletView/walletViewProvider";

function App() {
  const { isWalletView } = useContext(WalletViewContext);

  const language =
    window.faqProps?.language === FaqLanguage.RO
      ? FaqLanguage.RO
      : FaqLanguage.EN;
  const show =
    window.faqProps?.show ?? faqs[language as keyof typeof faqs].length;
  const view =
    window.faqProps?.view === FaqView.FOLD ? FaqView.FOLD : FaqView.PAGE;

  return (
    <div className={isWalletView ? "App wallet-view" : "App"}>
      {view === FaqView.PAGE && <FaqPage language={language} />}
      {view === FaqView.FOLD && (
        <FaqFold data={faqs} show={show} language={language} />
      )}
    </div>
  );
}

export default App;
