import { useContext, useEffect, useState } from "react";
import { WalletViewContext } from "../context/walletView/walletViewProvider";
import "./faq-fold.css";
import { FaqLanguage } from "..";

interface IFaq {
  id: number;
  question: string;
  answer: string;
  linkText?: string;
  linkUrl?: string;
  open: boolean;
}

const Faq = ({
  faq,
  index,
  toggleFaq,
}: {
  faq: IFaq;
  index: number;
  toggleFaq: any;
}) => {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFaq(index)}
    >
      <div className="faq-question">
        <span className="faq-id">{faq.id}. </span>
        {faq.question}
      </div>
      <div className="faq-answer">
        {faq.answer}{" "}
        {faq.linkText && faq.linkUrl && (
          <a href={faq.linkUrl} className="faq-link" target="_blank">
            {faq.linkText}
          </a>
        )}
      </div>
    </div>
  );
};

interface IFaqFoldProps {
  data: any;
  show: number;
  language: FaqLanguage;
}

const FaqFold = ({ data, show, language }: IFaqFoldProps) => {
  const { isWalletView } = useContext(WalletViewContext);

  const [faqs, setFaqs] = useState(
    data[language]
      .slice(0, show)
      .map((faq: IFaq) => Object.assign({}, faq, { open: false }))
  );

  useEffect(() => {
    setFaqs(
      data[language].slice(0, show).map((faq: IFaq) => {
        if (faq === data[language][0]) {
          return Object.assign({}, faq, { open: true });
        }
        return Object.assign({}, faq, { open: false });
      })
    );
  }, [language]);

  const toggleFaq = (index: number) => {
    setFaqs(
      faqs.map((faq: IFaq, i: number) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div id="faqFold" className="faq-fold">
      <div className="faq-container">
        {!isWalletView && (
          <div className="fold-title-section">
            <div className="fold-title-text">
              <h2 className="fold-title faq-fold-title">
                {language === FaqLanguage.EN
                  ? "Frequently Asked Questions"
                  : "Întrebări frecvente"}
              </h2>
            </div>
          </div>
        )}
        <div className="faqs">
          {faqs.map((faq: IFaq, index: number) => (
            <Faq faq={faq} index={index} key={index} toggleFaq={toggleFaq} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqFold;
